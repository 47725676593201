$(document).ready(function () {
    //背景圖
    initLazyload($('.online-exhibit-about'));
    initLazyload($('.online-exhibit-products'));
    initLazyload($('.online-exhibit-form '));
    
    Pace.on('done', function () {
       

        owlSlider(
            $('.owl-receipes'),
            10,
            1, {
                0: {
                    items: 1
                },
                600: {
                    items: 5
                },
                1280: {
                    items: 5
                }
            }, {
                margin: 5
            });

    });
});