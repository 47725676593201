$(document).ready(function () {
    common();

    bannerSlider();
    productDetailSlider();
    goToTop();
    question();
    blogSharing();
    productSharing();
    // activitySharing();

    //customer
    resetJsSocial();
    fullImageProdcutInEditor();
    productBoxLink();
    hotProductSlider();
    hotRecipeSlider();
    bgImagesLazyload();
    lazyYoutube();
    lazyMap();
    jumpProductOfTabs();
    // loadGoogleFont();

    //2021/12/28 
    //更新產品頁內的圖片 => 讓客人不用重覆改大量內文
    // changeProductViewCertificationImg();
});


//解決沒有要用按鈕客戶的產品在mobile會異常的問題
//不變更html
function productBoxLink() {
    var $productOverlay = $('.product-overlay');
    $productOverlay.click(function () {
        var $overlay = $(this);
        var url = $overlay.find('a').attr('href');
        window.location.href = url;
    });
}

function fullImageProdcutInEditor() {
    console.log('fullImageProdcutInEditor');
    Pace.on('done', function () {
        var $divs =
            $('#desc_0 p,#desc_0 div');
        var countOfDivs = $divs.length;
        console.log('fullImageProdcutInEditor: ' + countOfDivs);
        for (var i = 0; i < countOfDivs; i++) {
            var $currentDiv = $($divs[i]);
            console.log('editor div or p: ' + i);
            console.log($currentDiv);

            // if ($currentDiv.hasClass('row').length > 0) {
            //     $currentDiv.addClass('container');
            // }

            if ($currentDiv.find('img').length > 0) {
                //skip for full image
                // if($currentDiv.hasClass('row')){
                var classCol = $currentDiv.attr('class');
                console.log('find img class => ' + classCol);
                if (typeof (classCol) != 'undefined') {
                    if (classCol.indexOf('col') > -1) {
                        $currentDiv.addClass('container');
                    } else {
                        $currentDiv.addClass('full-width');
                    }
                } else {

                }

            } else {
                var classCol = $currentDiv.attr('class');
                console.log('cannot find img class => ' + classCol);
                if (typeof (classCol) != 'undefined') {
                    if (classCol.indexOf('col') > -1) {
                        $currentDiv.addClass('container');
                    }
                } else {
                    $currentDiv.addClass('container');
                }

            }
        }

        //避免有div col被加入container破版
        var $colsDiv = $('#desc_0').find('div[class*=col]');
        var lenOfColsDiv = $colsDiv.length;
        for (var i = 0; i < lenOfColsDiv; i++) {
            var $currentColDiv = $($colsDiv[i]);
            console.log('find cols =>' + i);
            console.log($currentColDiv);

            $currentColDiv.removeClass('container');

            var $childContainers = $currentColDiv.find('[class*=container]');
            var lenOfChildOfCols = $childContainers.length;
            console.log('cols child => ' + lenOfChildOfCols);
            for (var j = 0; j < lenOfChildOfCols; j++) {
                $($childContainers[j]).removeClass('container');
            }
        }

        //避免表格會滿版很醜
        var $tables =
            $('#desc_0 table');
        if ($tables.length > 0) {
            console.log('find table')
            var $parentOfTable =
                $('<div class="container fake-container"></div>');
            //將舊的表格插入fake container
            for (var i = 0; i < $tables.length; i++) {
                var $table = $($tables[i]);
                var $preElem = $table.prev();

                $preElem.append($parentOfTable);
                $table.appendTo($parentOfTable);

            }
        }
    });


};

function bannerSlider() {
    var bSlider = null;
    // Pace.on('done', function () {
        var $bannerSlider = $('#bannerSlider');
        if ($bannerSlider.length > 0) {
            if ($bannerSlider.find('.ms-view').length == 0) {
                setMasterSliderImageOnScreen($bannerSlider);

                try {
                    bSlider = new MasterSlider();
                    // adds Arrows navigation control to the slider.

                    bSlider.control('timebar', {
                        insertTo: '#bannerSlider'
                    });
                    bSlider.control('bullets', {
                        autohide: false,
                        dir: "h",
                        align: "bottom"
                    });

                    // bSlider.control('circletimer', {
                    //     autohide: false,
                    //     overVideo: true,
                    //     color: '#FFFFFF',
                    //     radius: 4,
                    //     stroke: 9
                    // });

                    bSlider.control('arrows', {
                        autohide: false
                    });
                    bSlider.setup('bannerSlider', {
                        width: 1920, // slider standard width
                        height: 974, // slider standard height
                        minHeight: 400,
                        autoHeight:true,
                        start: 1,
                        space: 0,
                        layout: 'fullwidth',
                        loop: true,
                        preload: 0,
                        instantStartLayers: false,
                        autoplay: true,
                        overPause: true,
                        view: 'fadeBasic'
                    });
                    // $(window).trigger('resize');
                    $('.master-skeleton-loader').remove();

                } catch (err) {
                    console.log(err);
                    removeErrorMasterSliderContainer($bannerSlider);
                }
            }

        }
    // });
}


function productDetailSlider() {
    var bSlider = null;
    // Pace.on('done', function () {
        var $productSlider = $('#productSlider');
        if ($productSlider.length > 0) {
            if ($productSlider.find('.ms-view').length == 0) {
                try {

                    bSlider = new MasterSlider();

                    bSlider.control(
                        'thumblist', {
                            autohide: false,
                            overVideo: true,
                            dir: 'h',
                            speed: 17,
                            inset: false,
                            arrows: false,
                            hover: false,
                            customClass: '',
                            align: 'bottom',
                            type: 'thumbs',
                            margin: 5,
                            width: 100,
                            height: 100,
                            space: 5
                        });

                    bSlider.setup('productSlider', {
                        width: 500,
                        height: 500,
                        space: 5,
                        view: 'fadeBasic'
                    });
                    // $(window).trigger('resize');

                } catch (err) {
                    console.log(err);
                    removeErrorMasterSliderContainer($productSlider);
                }
            }


        }
    // });
}


function goToTop() {
    $(window).scroll(function () {
        if ($(this).scrollTop() > 300) {
            $('.scrollToTop').fadeIn();
        } else {
            $('.scrollToTop').fadeOut();
        }
    });

    //Click event to scroll to top
    $('.scrollToTop').click(function () {
        $('html, body').animate({
            scrollTop: 0
        }, 800);
        return false;
    });
}

function question() {

    if (window.JUMP_DIR != '') {
        var $q4Elem = $("#" + window.JUMP_DIR);
        if ($q4Elem.length > 0) {
            $('html, body').animate({
                scrollTop: $q4Elem.offset().top
            }, 2000);

        }
    }

    var $questions =
        $('.questions');

    var $questionsTitle =
        $questions.find('li > h3');

    $questionsTitle.prepend('<i class="fa fa-caret-right" aria-hidden="true"></i> ');

    $questionsTitle.click(function (e) {

        var $expand = $(this).find('i');
        // console.log($expand);
        if ($expand.hasClass('fa-caret-right')) {
            //open
            var $answer =
                $(this).next();
            $answer.show();

            $expand.removeClass('fa-caret-right')
                .addClass('fa-caret-down');
        } else {
            //close
            var $answer =
                $(this).next();
            $answer.hide();

            $expand.removeClass('fa-caret-down')
                .addClass('fa-caret-right');
        }

    });

    var $answers =
        $questions.find('li > div');
    $answers.hide();

}


function blogSharing() {
    initJsSocial($('#blogSharing'));
}

function productSharing() {
    initJsSocial($('#productSharing'));
}

// function activitySharing() {
//     initJsSocial($('#activitySharing'));
// }

function initJsSocial($targetElem) {
    if ($targetElem.length > 0) {
        $targetElem.jsSocials({
            shares: ["twitter", "facebook", "linkedin"]
        });
    }
}

// customer
function resetJsSocial() {
    jsSocials.shares.facebook = {
        label: "Like",
        // logo: "fa fa-facebook",
        logo: MYAPP.endpoint + 'build/fufann/images/icons/facebook.png',

        shareUrl: "https://facebook.com/sharer/sharer.php?u={url}",
        countUrl: "https://graph.facebook.com/?id={url}",
        getCount: function (data) {
            return data.share && data.share.share_count || 0;
        }

    };

    jsSocials.shares.googleplus = {

        label: "+1",
        // logo: "fa fa-google",
        logo: MYAPP.endpoint + 'build/fufann/images/icons/google-plus.png',
        shareUrl: "https://plus.google.com/share?url={url}",
        countUrl: ""

    };

    jsSocials.shares.linkedin = {
        label: "Share",
        // logo: "fa fa-linkedin",
        logo: MYAPP.endpoint + 'build/fufann/images/icons/linkedin.png',

        shareUrl: "https://www.linkedin.com/shareArticle?mini=true&url={url}",
        countUrl: "https://www.linkedin.com/countserv/count/share?format=jsonp&url={url}&callback=?",
        getCount: function (data) {
            return data.count;
        }
    };

    jsSocials.shares.twitter = {
        logo: MYAPP.endpoint + 'build/fufann/images/icons/twitter.png',
        label: "Tweet",
        // logo: "fa fa-twitter",
        shareUrl: "https://twitter.com/share?url={url}&text={text}&via={via}&hashtags={hashtags}",
        countUrl: "https://cdn.api.twitter.com/1/urls/count.json?url={url}&callback=?",
        getCount: function (data) {
            return data.count;
        }
    };

}

function hotProductSlider() {
    Pace.on('done', function () {
        owlSlider(
            $('.product-row'),
            4,
            1, {
                0: {
                    items: 1
                },

                1200: {
                    items: 4
                }
            });
    });
}

function hotRecipeSlider() {
    Pace.on('done', function () {
        owlSlider(
            $('.recipe-row'),
            4,
            1, {
                0: {
                    items: 1
                },
                600: {
                    items: 4
                },
                1000: {
                    items: 4
                }
            });

    });
}

function owlSlider($owlSlider, items, slideBy, responsive, optsCustomer) {
    console.log('owlSlider');
    if ($owlSlider.length > 0) {
        var opts = {
            autoplay: 1000,
            // loop: true,
            // animateOut: 'fadeOut',
            // animateIn: 'fadeIn',
            // autoWidth: true,
            // stagePadding: 50,
            // center: true,
            // margin: 10,
            // items: items,
            // dots: true,
            // nav: true,
            // // lazyLoad: true,
            // slideBy: slideBy
            autoWidth: true,
            loop: true,
            center: true,
            margin: 10,
            nav: true,
            items: items,
            slideBy: slideBy
        }
        if (typeof (optsCustomer) != undefined)
            opts = $.extend(opts, optsCustomer);
        if (typeof (responsive) != 'undefined') {
            opts.responsive = responsive;
        }
        $owlSlider.owlCarousel(opts);
        var $owlImages = $owlSlider.find('img');
        $owlImages.on('inview', function (event, isInView) {
            if (isInView) {

                var $img = $(this);
                // console.log($img);
                var imgUrl = $img.attr('data-lazy');
                if (typeof (imgUrl) != 'undefined') {
                    $img.attr('src', $img.attr('data-lazy'));
                    console.log('owl img inview:' + $img.attr('data-lazy'));
                    $img.removeAttr('data-lazy');
                } else {
                    // alert(imgUrl);
                }

            }
        });
    }

}

function bgImagesLazyload() {
    initLazyload($('#valueBoxTitleImgBg'));
    initLazyload($('#sectionAboutEnBg'));
    initLazyload($('#contentIsoBg'));
    initLazyload($('.section-youtube'));
}


function lazyYoutube() {
    Pace.on('done', function () {
        initViewLazyOfIframe($('#homeYt'));
    });
}



function loadGoogleFont() {
    Pace.on('done', function () {
        WebFont.load({
            timeout: 2000,
            // google: {
            //     families: []
            // },
            custom: {
                families: [
                    'BNHRDFAN',
                    'HuaKangTiFan',
                    'Lucida Handwriting'
                ],

            }
        });
    });

}

function lazyMap() {
    Pace.on('done', function () {
        initViewLazyOfIframe($('#contactMap'));
    });
}

function jumpProductOfTabs() {

    var $tabContent = $('.tabs-nav');
    if ($tabContent.length > 0) {
        $tabContent.find('li > a').click(function () {
            var $tabLink = $(this);
            console.log('click href: ' + $tabLink.attr('href'));
            if (MYAPP.isMobile) {
                jumpSection($tabLink.attr('href'), 500, 500);
            }
        });

        //如果是手機，等tabInView載入完畢即可載入所有圖片
        if (MYAPP.isMobile) {
            var isLazyLoadUnderTab = false;
            var $productDetail = $('.product-detail');
            $productDetail.on('inview', function (event, isInView) {
                if (isInView && isLazyLoadUnderTab == false) {
                    isLazyLoadUnderTab = true;
                    var $imgsOfTabs = $tabContent.find('img');
                    startLoadImages($imgsOfTabs);
                }
            });
        }
    }

}


function initViewLazyOfIframe($targetElem) {
    
    if ($targetElem.length > 0) {
        $targetElem.on('inview', function (event, isInView) {
            if (isInView) {
                if ($targetElem.data('isEnable') != true) {
                    $targetElem.attr('src', $targetElem.attr('data-url'));
                    $targetElem.data('isEnable',true);
                }
            }
        });
    }
}

